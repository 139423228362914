//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
    documentBillAuditPage,
    documentBillHomePage,
    documentBillIndexPage,
} from '@/api/modular/fileStream/documentManage'
import { sysDictTypeDropDown } from '@/api/modular/system/dictManage'
import { STable } from '@/components'
import { stateColor } from '@/mixins/index.js'
import { handleAppointEleHeight } from '@/utils/common.js'
import { getFilters } from '@/views/fileStream/documentManage/config'
import OpenData from '@/views/fileStream/documentManage/weixin/openData'
import { init_wx } from '@/views/fileStream/documentManage/weixin/weixin'
const defaultColumns = [
    {
        title: '處理箋名稱',
        dataIndex: 'name',
        ellipsis: true,
    },
    {
        title: '標題',
        dataIndex: 'title',
        ellipsis: true
    },
    {
        title: '辦文/發文號',
        dataIndex: 'serialNumber',
        scopedSlots: { customRender: 'serialNumber' },
        ellipsis: true,
    },
    // {
    //     title: '來文/發文字號',
    //     dataIndex: 'documentNumber',
    //     ellipsis: true,
    // },
    {
        title: '緊急程度',
        dataIndex: 'urgencyDegree',
        scopedSlots: { customRender: 'urgencyDegree' },
    },
    {
        title: '密級程度',
        dataIndex: 'secrecyDegree',
        scopedSlots: { customRender: 'secrecyDegree' },
    },
    {
        title: '類型',
        dataIndex: 'documentType',
        scopedSlots: { customRender: 'documentType' },
    },
    {
        title: '經辦/擬稿人',
        dataIndex: 'draftId',
        scopedSlots: { customRender: 'draftId' },
    },

    {
        title: '創建日期',
        dataIndex: 'createTime',
        scopedSlots: { customRender: 'createTime' },
    },
    // {
    //   title: '收文日期',
    //   dataIndex: 'acceptTime',
    //   scopedSlots: { customRender: 'acceptTime' },
    // },
    {
        title: '狀態',
        dataIndex: 'acceptStatus',
        scopedSlots: { customRender: 'acceptStatus' },
    },
    {
        title: '操作',
        dataIndex: 'action',
        scopedSlots: { customRender: 'action' },
    },
]
export default {
    name: 'list',
    components: {
        STable,
        OpenData,
    },
    mixins: [stateColor],
    computed: {
        columns() {
            let arr = JSON.parse(JSON.stringify(defaultColumns))
            if (this.queryParam.approvalTab === 1)
                arr = arr.filter((j) => !['approvalStatus', 'acceptTime'].includes(j.dataIndex))
            if (this.queryParam.approvalTab === 0) {
                arr.forEach((j) => {
                    if (j.dataIndex === 'acceptStatus') {
                        j.filterMultiple = false
                        j.filters = getFilters(this.flow_approval_status)
                    }
                })
            }
            return arr
        },
    },
    watch: {
        '$route.query.index': {
            handler() {
                this.changeTabStatus(+this.$route.query.index || 0)
            },
            immediate: true,
        },
    },
    data() {
        const formatDraftId = (id) => {
            if (!id) return []
            return JSON.parse(id)
        }
        return {
            btna: ['全部', '待收公文', '待審公文'],
            btck: 0,
            // 查詢參數
            queryParam: { approvalTab: 1 },
            func: documentBillIndexPage,
            // 表頭
            // columns: defaultColumns,
            // 加載數據方法 必須為 Promise 對象
            loadData: (parameter) => {
                const { acceptStatus } = parameter
                let params = { ...parameter }
                if (acceptStatus && acceptStatus.length > 0) params = { ...parameter, approvalStatus: acceptStatus[0] }
                delete params.acceptStatus
                return this.func(Object.assign(params, this.queryParam)).then((res) => {
                    const data = { ...res.data }
                    data.rows = data.rows.map((ele) => {
                        ele.draftId = formatDraftId(ele.draftId)
                        return ele
                    })
                    handleAppointEleHeight('#ygh-content-box', ['.container', '.box-btns', '.s-table-tool'], 66, res)
                    return data
                })
            },
            selectedRowKeys: [],
            selectedRows: [],
            flow_secrecy_degree: [],
            flow_urgency_degree: [],
            flow_file_type: [],
            flow_approval_status: [],
            selectedCorpGroupDepartmentList: '',
            selectedDepartmentList: '',
        }
    },
    created() {
        this.getDict()
        //
        init_wx()
    },
    mounted() {
        handleAppointEleHeight('#ygh-content-box', ['.container', '.box-btns', '.s-table-tool'])
    },
    methods: {
        Filter(arr, s) {
            const values = arr.filter((item) => item.code == s)
            if (values.length > 0) {
                return values[0].value
            }
        },
        translateToRe() {
            // this.$router.push({ path: '/fileStreamDocumentManage', replace: true })
            this.$router.push({ name: 'mobileApproval', query: { id: '1610556553377624066' } })
        },
        foramtAcceptStatus(text) {
            switch (Number(text)) {
                case 0:
                    return '待接收'
                case 1:
                    return '已接收'
                default:
                    return ''
            }
        },
        acceptBill({ id, documentType }) {
            let url = '/fileStream_Approval_process'
            if (documentType === 2) {
                url = '/fileStreamDocumentSend'
            }
            this.$router.push({ path: url, query: { id, type: documentType, todo: 'accept' } })
        },
        /**
         * 獲取字典數據
         */
        getDict() {
            sysDictTypeDropDown({ code: 'flow_secrecy_degree' }).then((res) => {
                this.flow_secrecy_degree = res.data
            })
            sysDictTypeDropDown({ code: 'flow_urgency_degree' }).then((res) => {
                this.flow_urgency_degree = res.data
            })
            sysDictTypeDropDown({ code: 'flow_file_type' }).then((res) => {
                this.flow_file_type = res.data
            })
            sysDictTypeDropDown({ code: 'flow_approval_status' }).then((res) => {
                this.flow_approval_status = res.data
            })
        },
        onSearch(value) {
            this.$refs.table.refresh(true)
        },
        onSelectChange(selectedRowKeys, selectedRows) {
            this.selectedRowKeys = selectedRowKeys
            this.selectedRows = selectedRows
        },
        changeTabStatus(i) {
            this.btck = i
            this.queryParam.approvalTab = i > 0 ? 1 : i
            if (+i == 2) {
                this.func = documentBillAuditPage
                this.queryParam.documentType = i
            } else if (i === 0) {
                this.func = documentBillHomePage
            } else {
                delete this.queryParam.documentType
                this.func = documentBillIndexPage
            }

            this.$refs.table && this.$refs.table.refresh(true)
        },
        change_status(i) {
            if (this.btck === i) return
            this.$router.push({
                query: {
                    index: i,
                },
            })
        },
        edit(record) {
            this.$router.push({ path: '/fileStreamDocumentManage', query: { id: record.id } })
        },
        senpi(record) {
            const { approvalId, nodeId, id } = record
            this.$router.push({
                path: '/fileStream_Approval_Manage',
                query: { id, approvalId, nodeId, type: record.documentType },
            })
        },
        see(record) {
            let url = '/fileStream_Approval_process'
            if (record.documentType == 2) {
                url = '/fileStreamDocumentSend'
            }
            this.$router.push({ path: url, query: { id: record.id, type: record.documentType } })
        },
    },
}
