//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import dialogHome from './dialogHome.vue'
import tab from './tab.vue'
// 快捷入口
import { pendingNumber } from '@/api/modular/fileStream/documentManage'
import { mapGetters } from 'vuex'
import dialogApproval from './components/dialogApproval.vue'
import fastEntry from './components/fastEntry.vue'
import list from './list.vue'
export default {
    name: 'home',
    components: { tab, list, dialogHome, fastEntry, dialogApproval },
    data() {
        return {
            items: [
                {
                    url: '',
                    text: '快速發文',
                    img: require('@/assets/img/ksfw.png'),
                    route: { path: '/fileStreamDocumentSend' },
                    storeKey: 'bill/setPostMsg',
                },
                {
                    url: '',
                    text: '收文登記',
                    img: require('@/assets/img/swdj.png'),
                    route: { path: '/fileStreamDocumentManage' },
                    storeKey: 'bill/setRegistration',
                },
            ],
            pendingNumber: 0,
        }
    },
    computed: {
        ...mapGetters(['auth_code']),
    },
    mounted() {
        console.log(process.env.VUE_PRIVE_BASE_URL, 'process.env.VUE_PRIVE_API_BASE_UR', this.auth_code)
        this.initNumber()
    },
    methods: {
        fastTodo(o) {
            this.$store.commit(o.storeKey, true)
            this.$router.push(o.route)
        },
        initNumber() {
            pendingNumber()
                .then((res) => {
                    if (res.code === 200) {
                        this.pendingNumber = res.data
                    }
                })
                .catch((err) => {
                    console.log(err)
                })
        },
    },
}
