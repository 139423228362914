//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Ellipsis, FullModelView } from '@/components'
import formBox from '@/views/fileStream/documentManage/fromBox.vue'
export default {
  components: {
    Ellipsis,
    FullModelView,
    formBox,
  },
  data() {
    return {
      title: '新增',
      subTitle: '收文登記',
      isDisabled: false, // 是否可編輯
      visible: false,
      todoType: '',
      officialId: '',
      randomCode: null, // 亂碼 保證每次進入請求最新數據
    }
  },
  methods: {
    open({ type, id }) {
      this.isDisabled = false
      this.visible = true
      if (type === 'approval') {
        this.officialId = id
        this.title = '發起審批'
        this.todoType = 'registration'
        this.randomCode = Math.floor(Math.random() * 1024 * 1024 * 1024)
      }
    },
    close() {
      this.visible = false
    },
    handleCancel() {
      this.visible = false
    },
  },
}
