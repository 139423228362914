//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { closePopup, getPopup } from '@/api/modular/fileStream/documentManage'
import { mapState } from 'vuex'
import dialogContent from './components/dialogContent.vue'
import dialogTitle from './components/dialogTitle.vue'
export default {
  data() {
    return {
      visible: false,
      index: 0,
    }
  },
  computed: {
    ...mapState({
      isShowGuide: (state) => state.bill.isShowGuide,
    }),
  },
  components: {
    dialogTitle,
    dialogContent,
  },
  mounted() {
    this.judgeIsShow()
  },
  methods: {
    knowed() {
      this.visible = false
      this.$store.commit('bill/setIsShowGuide', false)
    },
    judgeIsShow() {
      if (this.isShowGuide) {
        getPopup()
          .then((res) => {
            if (res.code === 200) {
              const { status } = res.data
              if (status === 0) {
                this.visible = true
              } else {
                this.visible = false
              }
              this.$store.commit('bill/setIsShowGuide', false)
            }
          })
          .catch((err) => {
            console.log(err)
          })
      } else {
        this.visible = false
      }
    },
    notTip() {
      closePopup({
        status: 1,
      }).then((res) => {
        if (res.code === 200) {
          this.visible = false
        }
      })
    },
  },
}
